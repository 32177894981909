import { emissionsCategoriesTags } from "@aclymatepackages/subcategories";

export const findThisTagIconColor = (name) => {
  return emissionsCategoriesTags.find((tag) => tag.name === name) || {};
};

const formatTagDataObj = (tag, data) => {
  if (!data) {
    return {};
  }

  const { name, id, tonsCo2ePerMile, vendorScore } = data;
  const tonsCo2ePerMileObj = tonsCo2ePerMile
    ? {
        tonsCo2ePerMile,
      }
    : {};
  const vendorScoreObj = vendorScore ? { vendorScore } : {};

  return {
    [tag]: {
      name,
      id,
      ...tonsCo2ePerMileObj,
      ...vendorScoreObj,
    },
  };
};

export const buildFormattedTagsObjs = ({
  employees,
  vehicle,
  office,
  knownVendor,
}) => {
  const buildEmployeeTagProps = () => {
    if (!employees) {
      return {};
    }

    const formattedEmployees = employees.map(({ name, id }) => ({ name, id }));
    const employeeIds = formattedEmployees.map(({ id }) => id);
    return {
      employees: formattedEmployees,
      taggedEmployeesIds: employeeIds,
    };
  };

  const formattedEmployees = buildEmployeeTagProps();
  const formattedVehicle = formatTagDataObj("vehicle", vehicle);
  const formattedOffice = formatTagDataObj("office", office);
  const formattedVendor = formatTagDataObj("knownVendor", knownVendor);

  return {
    ...formattedEmployees,
    ...formattedVehicle,
    ...formattedOffice,
    ...formattedVendor,
  };
};
