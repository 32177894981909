import { createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { splitScopeEmissions } from "@aclymatepackages/chart-helpers";
import { buildCompanyEmissionsArrays } from "@aclymatepackages/calcs/recurring";

import { emissionStatuses } from "../components/primaryView";
import { PlatformLayoutContext } from "./platformLayout";
import { SandboxDataContext } from "./sandboxData";
import { AdminDataContext } from "./adminData";

import { useCachedDisplayData, useAccountData } from "../firebase";

import useAccountingData from "../hooks/accountingData";
import { useDisplayEvents } from "../components/events";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

const assignEmissionStatuses = (emissions, mostRecentAccountingDate) => {
  const buildEmissionStatus = ({ id, date, status }) => {
    if (dayjs(date).isAfter(dayjs())) {
      return "cleared";
    }

    if (
      mostRecentAccountingDate &&
      dayjs(date).isBefore(dayjs(mostRecentAccountingDate))
    ) {
      return "locked";
    }

    if (id && status !== "confirmed") {
      return "unconfirmed";
    }

    return "confirmed";
  };

  return emissions.map((emission) => {
    const currentStatus = buildEmissionStatus(emission);
    const { severity } = emissionStatuses[currentStatus] || {};

    return { ...emission, currentStatus, severity };
  });
};

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode = "company" } = useContext(PlatformLayoutContext) || {};
  const { sandboxData } = useContext(SandboxDataContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { companyData: sandboxCompanyData } = sandboxData || {};
  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: sandboxStartDate } = sandboxCompanyData || {};
  const { startDate: adminStartDate } = adminCompanyData || {};

  const [accountData, accountDataLoading] = useAccountData();
  const [{ mostRecentAccountingDate }, accountingDataLoading] =
    useAccountingData();
  const { startDate: companyStartDate } = accountData;

  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [dbTransactions, transactionsLoading] =
    useCachedDisplayData("transactions");
  const [events, eventsLoading] = useDisplayEvents();

  const recurringEmissionsLoading =
    employeesLoading ||
    officesLoading ||
    accountDataLoading ||
    transactionsLoading ||
    eventsLoading ||
    accountingDataLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    sandbox: sandboxStartDate,
    company: companyStartDate,
  };
  const emissionsStartDate = emissionStartDates[viewMode];

  const {
    emissionsMonths,
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    totalRecurringEmissionsTons,
    averageMonthlyRecurringEmissionsTons,
    separateUtilitiesOfficesDefaultEmissions,
    transactions,
    allEmissions,
  } = buildCompanyEmissionsArrays({
    companyData: {
      ...accountData,
      startDate: emissionsStartDate,
    },
    employees,
    offices,
    transactions: dbTransactions,
    events,
  });

  const emissionsStatuses = assignEmissionStatuses(
    allEmissions,
    mostRecentAccountingDate
  );

  const splitEmissions = splitScopeEmissions(emissionsStatuses);

  const taggedOfficeTransactions = [
    ...transactions,
    ...separateUtilitiesOfficesDefaultEmissions,
  ].filter(({ taggedOfficeId }) => taggedOfficeId);

  const taggedEmployeesTransactions = transactions.filter(
    ({ taggedEmployeesIds }) => taggedEmployeesIds
  );

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        recurringEmissionsLoading,
        separateUtilitiesOfficesDefaultEmissions,
        transactions,
        allEmissions: splitEmissions,
        taggedOfficeTransactions,
        taggedEmployeesTransactions,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
