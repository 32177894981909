import React, { useState, useEffect, useMemo } from "react";

import { Grid, InputAdornment } from "@mui/material";

import { TextField } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import NaicsCodesAutocomplete from "../autocomplete/NaicsCodesAutocomplete";

import ScopeThreeCategorySelect from "../vendors/ScopeThreeCategorySelect";

const SpendBasedInputBlock = ({
  value,
  setValue,
  naicsCode,
  setNaicsCode,
  scopeThreeCategory,
  setScopeThreeCategory,
  isValueDisabled,
  areNaicsInputsDisabled,
}) => {
  return (
    <Grid item container spacing={2} direction="column">
      <Grid item>
        <TextField
          value={numbersRegExpTest(value) ? Math.abs(value) : value}
          setValue={setValue}
          label="Dollar Value"
          InputProps={{ startAdornment: <InputAdornment>$</InputAdornment> }}
          disabled={isValueDisabled}
        />
      </Grid>
      <Grid item>
        <NaicsCodesAutocomplete
          naicsCode={naicsCode}
          setNaicsCode={setNaicsCode}
          disabled={areNaicsInputsDisabled}
        />
      </Grid>
      <Grid item>
        <ScopeThreeCategorySelect
          scopeThreeCategory={scopeThreeCategory}
          setScopeThreeCategory={setScopeThreeCategory}
          disabled={areNaicsInputsDisabled}
        />
      </Grid>
    </Grid>
  );
};

const useSpendBasedInput = ({ transaction = {}, onSave }) => {
  const {
    value: existingValue = 0,
    knownVendor,
    source,
    naicsCode: transactionNaicsCode,
    naicsTitle: transactionNaicsTitle,
    tonsCo2ePerDollar: transactionTonsCo2ePerDollar,
    scopeThreeCategory: transactionScopeThreeCategory,
  } = transaction;

  const {
    naicsCode: knownVendorNaicsCode = "",
    naicsTitle: knownVendorNaicsTitle,
    tonsCo2ePerDollar: knownVendorCo2PerDollar,
    scopeThreeCategory: knownVendorScopeThreeCategory,
  } = knownVendor || {};

  const initialNaicsCodeValue = useMemo(
    () => ({
      naicsCode: knownVendorNaicsCode || transactionNaicsCode,
      name: knownVendorNaicsTitle || transactionNaicsTitle,
      tonsCo2ePerDollar:
        knownVendorCo2PerDollar || transactionTonsCo2ePerDollar,
    }),
    [
      knownVendorNaicsCode,
      transactionNaicsCode,
      knownVendorNaicsTitle,
      transactionNaicsTitle,
      knownVendorCo2PerDollar,
      transactionTonsCo2ePerDollar,
    ]
  );
  const initialScopeThreeCategoryValue =
    knownVendorScopeThreeCategory || transactionScopeThreeCategory;

  const [value, setValue] = useState(existingValue);
  const [selectedNaicsCode, setSelectedNaicsCode] = useState(
    initialNaicsCodeValue
  );
  const [scopeThreeCategory, setScopeThreeCategory] = useState(
    initialScopeThreeCategoryValue
  );

  useEffect(() => {
    if (knownVendor) {
      setSelectedNaicsCode(initialNaicsCodeValue);
      setScopeThreeCategory(initialScopeThreeCategoryValue);
    }
  }, [knownVendor, initialNaicsCodeValue, initialScopeThreeCategoryValue]);

  const {
    name: naicsTitle,
    naicsCode,
    tonsCo2ePerDollar,
  } = selectedNaicsCode || {};

  const tonsCo2e = Math.abs(value * tonsCo2ePerDollar);

  const onTransactionSave = () =>
    onSave({
      naicsCode,
      naicsTitle,
      tonsCo2e,
      tonsCo2ePerDollar,
      value,
      scopeThreeCategory,
    });
  const saveEnabled = selectedNaicsCode && value && numbersRegExpTest(value);

  const isValueDisabled = source !== "manual-entry" && !!knownVendor;

  const inputBlock = (
    <SpendBasedInputBlock
      value={value}
      setValue={setValue}
      naicsCode={naicsCode}
      setNaicsCode={setSelectedNaicsCode}
      scopeThreeCategory={scopeThreeCategory}
      setScopeThreeCategory={setScopeThreeCategory}
      isValueDisabled={isValueDisabled}
      areNaicsInputsDisabled={!!knownVendor}
    />
  );

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useSpendBasedInput;
