import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { usePDF } from "react-to-pdf";

import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Avatar,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartLine } from "@fortawesome/pro-solid-svg-icons";

import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  buildEmissionsSummarySentence,
  filterEmissionsByScope,
} from "@aclymatepackages/other-helpers";
import { formatDate } from "@aclymatepackages/formatters";
import { TextField, Backdrop, AnimatedLogo } from "@aclymatepackages/atoms";
import searchSubcategories, {
  buildScopesWithColors,
} from "@aclymatepackages/subcategories";
import {
  ReportGraphContentLayout,
  ReportSummaryBlock,
} from "@aclymatepackages/modules";
import { filterByDateRange } from "@aclymatepackages/date-helpers";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import TableSkeleton from "../../atoms/loading/TableSkeleton";
import EditableContentBlock from "../../atoms/EditableContentBlock";

import DateRangeFilter from "../../modules/filter/DateRangeFilter";
import DateIntervalToggles from "../../modules/DateIntervalToggles";
import LogoUploadDropzone from "../../modules/LogoUploadDropzone";

import ViewTitleActionLayout from "../../layouts/ViewTitleActionLayout";
import LoadingCardLayoutBox from "../../layouts/LoadingCardLayoutBox";

import useEmissionsContext from "../../../helpers/contexts/emissions";
import { useAccountData } from "../../../helpers/firebase";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const PIXELS_PER_INCH = 96;
const PAGE_HEIGHT = PIXELS_PER_INCH * 11;

const DEFAULT_COMPANY_STATEMENT =
  "Share your company's mission and climate journey with the world. Enter your own text here.";

const filterEmissionsBySubcategory = (emissions, subcategory) =>
  emissions.filter((emission) => emission.subcategory === subcategory);

const findUniqueEmissionsSubcategories = (emissions) => [
  ...new Set(emissions.map(({ subcategory }) => subcategory)),
];

const scopes = [
  {
    scope: 1,
    description:
      "Scope 1 emissions include activities where your company is burning fossil fuels directly. This most often includes activities like gas utilities and vehicle fuel consumption.",
  },
  {
    scope: 2,
    description:
      "Scope 2 emissions include activities where someone is burning fossil fuels to provide your company energy in the form of electricity. These emissions are exclusively generated by your company's electric utility bills. ",
  },
  {
    scope: 3,
    description:
      "Scope 3 emissions are the vast category of emissions that cover all of the other activities that your company engages in where you aren't directly burning fossil fuels or consuming electricity",
  },
];

const EmissionsSummarySentence = ({ label, emissions }) => (
  <Typography variant="body2">
    {buildEmissionsSummarySentence({ label, emissions })}
  </Typography>
);

const TitleBlock = ({ name, startDate, endDate }) => {
  const { palette } = useTheme();

  const disclaimerTexts = [
    "Report prepared by Aclymate",
    `Report date: ${formatDate(dayjs())}`,
    `Reporting Period: ${formatDate(startDate)} - ${formatDate(endDate)}`,
  ];

  return (
    <Box p={1} style={{ backgroundColor: palette.backgroundGray.main }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img
                src="/images/aclymate-black-logo.png"
                width={40}
                alt="black-aclymate-logo"
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">Emissions report for {name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {disclaimerTexts.map((text, idx) => (
            <Typography
              key={`disclaimer-text-${idx}`}
              variant="subtitle2"
              align="right"
            >
              {text}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const EditableTextBlock = ({
  placeholder,
  textAlign = "left",
  variant = "body2",
  label,
}) => {
  const [customStatement, setCustomStatement] = useState("");
  const [editingStatement, setEditingStatement] = useState("");

  return (
    <Grid item>
      <EditableContentBlock
        onSave={() => setCustomStatement(editingStatement)}
        content={
          <Typography variant={variant} align={textAlign}>
            {customStatement || placeholder}
          </Typography>
        }
        editingInterface={
          <TextField
            label={label}
            value={editingStatement}
            setValue={setEditingStatement}
            multiline
            placeholder={placeholder}
          />
        }
      />
    </Grid>
  );
};

const CompanyStatemenBlock = () => {
  return (
    <Box p={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" align="center">
            Emissions Report
          </Typography>
        </Grid>
        <EditableTextBlock
          label="Provide a custom statement for this report"
          placeholder={DEFAULT_COMPANY_STATEMENT}
        />
      </Grid>
    </Box>
  );
};

const ReportSectionBreak = ({ title }) => {
  const { palette } = useTheme();

  return (
    <Box p={2} style={{ backgroundColor: palette.backgroundGray.main }}>
      <Typography variant="h3" align="center">
        {title}
      </Typography>
    </Box>
  );
};

const ScopeEmissionsReport = ({ scope, description, emissions, interval }) => {
  const uniqueEmissionsSubcategories =
    findUniqueEmissionsSubcategories(emissions);

  return (
    <>
      <ReportGraphContentLayout
        contentTitle={`Scope ${scope} Emissions`}
        interval={interval}
        emissions={emissions}
        contentRows={[
          <EditableTextBlock
            placeholder={description}
            label={`Scope ${scope} summary`}
          />,
          <EmissionsSummarySentence
            label={`Total Scope ${scope} Emissions`}
            emissions={emissions}
          />,
        ]}
      />
      <ReportGraphContentLayout
        emissions={emissions}
        interval={interval}
        contentTitle={`Scope ${scope} emissions by category`}
        contentRows={uniqueEmissionsSubcategories.map((subcategory, idx) => (
          <EmissionsSummarySentence
            key={`scope-subcategory-summary-${idx}`}
            label={searchSubcategories(subcategory).name}
            emissions={filterEmissionsBySubcategory(emissions, subcategory)}
          />
        ))}
        chartType="area"
        chartViewMode="subcategories"
        isPercentageChart
      />
    </>
  );
};

const ReportingBlock = () => {
  const { palette } = useTheme();

  const { allEmissions, recurringEmissionsLoading } = useEmissionsContext();

  const [{ branding, name, startDate }, companyDataLoading] = useAccountData();
  const { primaryColor, secondaryColor, logoUrl } = branding || {};

  const pageLoading = recurringEmissionsLoading || companyDataLoading;

  const [graphPeriod, setGraphPeriod] = useState("quarter");
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().startOf("year")
  );

  const [filterEndDate, setFilterEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (logoUrl) {
      fetchOurApi({
        path: "/helpers/convert-image",
        method: "POST",
        data: { imageUrl: logoUrl },
        callback: (res) => setImageString(res),
      });
    }
  }, [logoUrl]);

  const { targetRef, toPDF } = usePDF({
    filename: "aclymate-emission-report",
    page: { format: "letter" },
  });

  const onExport = async () => {
    setExportLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    toPDF();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setExportLoading(false);
  };

  const dateFilteredEmissions = allEmissions.filter((emission) =>
    filterByDateRange({ filterEndDate, filterStartDate, ...emission })
  );

  const scopesWithColors = buildScopesWithColors(branding);

  return (
    <>
      {exportLoading && (
        <Backdrop>
          <AnimatedLogo />
        </Backdrop>
      )}
      <LoadingCardLayoutBox
        backgroundColor={palette.primary.main}
        style={{ minHeight: "100vh" }}
      >
        <Grid container spacing={2} direction="column" wrap="nowrap">
          <Grid item>
            <ViewTitleActionLayout
              viewLoading={recurringEmissionsLoading}
              avatar={
                <Avatar style={{ backgroundColor: palette.secondary.main }}>
                  <FontAwesomeIcon icon={faFileChartLine} />
                </Avatar>
              }
              title="Custom Reporting"
              subtitle="Customize and export a report showing your emissions over a period of time"
              primaryAction={
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <DateRangeFilter
                      filterStartDate={filterStartDate}
                      setFilterStartDate={setFilterStartDate}
                      filterEndDate={filterEndDate}
                      setFilterEndDate={setFilterEndDate}
                      initialIntervalSelected="year-to-date"
                    />
                  </Grid>
                  <Grid item>
                    <DateIntervalToggles
                      graphPeriod={graphPeriod}
                      setGraphPeriod={setGraphPeriod}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onExport()}
                    >
                      Export
                    </Button>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item>
            {pageLoading ? (
              <TableSkeleton />
            ) : (
              <Grid container justifyContent="center">
                <Grid item>
                  <Paper
                    style={{
                      overflow: "hidden",
                      width: "8.5in",
                      position: "relative",
                    }}
                    ref={targetRef}
                  >
                    <Box
                      style={{ height: exportLoading ? PAGE_HEIGHT : "auto" }}
                    >
                      <TitleBlock
                        name={name}
                        startDate={filterStartDate || startDate}
                        endDate={filterEndDate || dayjs()}
                      />
                      <Box
                        p={4}
                        style={{
                          height: "150px",
                          background: `linear-gradient(30deg, ${
                            primaryColor || palette.primary.main
                          } 0%, ${
                            secondaryColor || palette.secondary.main
                          } 100%)`,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ThemeProvider theme={mergeDarkTheme}>
                          <Typography
                            variant="h2"
                            align="center"
                            color="textPrimary"
                          >
                            Carbon Accounting Report for {name}
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <CompanyStatemenBlock />
                      <ReportSectionBreak title="Summary" />
                      <ReportSummaryBlock
                        emissions={dateFilteredEmissions}
                        interval={graphPeriod}
                        scopesWithColors={scopesWithColors}
                        branding={branding}
                      />
                    </Box>
                    {scopes.map(({ scope, description }, idx) => (
                      <Box
                        key={`scope-report-section-${idx + 1}`}
                        style={{
                          height: exportLoading ? PAGE_HEIGHT : "auto",
                        }}
                      >
                        <ReportSectionBreak
                          title={`Scope ${scope} Emissions`}
                        />
                        <ScopeEmissionsReport
                          emissions={filterEmissionsByScope(
                            dateFilteredEmissions,
                            scope
                          )}
                          interval={graphPeriod}
                          scope={scope}
                          description={description}
                        />
                      </Box>
                    ))}
                    {/* <EmissionsSummaryBlock
                      emissions={dateFilteredEmissions}
                      interval={graphPeriod}
                      footerHeight={FOOTER_HEIGHT + DISCLAIMER_HEIGHT + 1}
                      exportLoading={exportLoading}
                    /> */}
                    <Box
                      p={2}
                      style={{ backgroundColor: palette.backgroundGray.main }}
                    >
                      <Typography variant="body2" align="center">
                        This report has been prepared using Aclymate Inc by{" "}
                        {name} for the purpose of reporting company emissions.
                        Aclymate follows all GHG protocol guidelines and has
                        generated this report based on both actual and
                        spend-based accounting methodologies. All Scope
                        Emissions in this report are based on the information{" "}
                        {name} has input.
                      </Typography>
                    </Box>
                    <Box
                      p={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: exportLoading ? 144 : "auto" }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="stretch"
                        justifyContent="space-around"
                      >
                        <Grid item sm={5}>
                          <img
                            alt="aclymate logo"
                            src="/images/aclymate-full-logo.png"
                            style={{ width: "100%", maxWidth: "600px" }}
                          />
                        </Grid>
                        <Grid item sm={5}>
                          {exportLoading ? (
                            <Grid container justifyContent="center">
                              <Grid item>
                                <img
                                  alt="encoded company logo"
                                  style={{ height: "74px" }}
                                  src={`data:image/jpeg;base64, ${imageString}`}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <LogoUploadDropzone />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LoadingCardLayoutBox>
    </>
  );
};

const Reporting = () => (
  <ErrorBoundary>
    <ReportingBlock />
  </ErrorBoundary>
);

export default Reporting;
